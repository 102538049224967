import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'
import tecne_logo from '../../assets/images/projects/service/tecne/tecne-logo.jpg'
import adobelogo from '../../assets/images/projects/service/mercedes/adobe_logo.png'
// import pic02 from '../../assets/images/projects/service/bsmart/19.png'
import pic04 from '../../assets/images/projects/22.png'
import { useRef } from 'react'





const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignTecne = props => {

  const outcomeDiv = useRef(null);

  const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  return (
  <Layout>
    <Helmet>
      <title>Service Design - Tecne</title>
      <meta name="Service Design - Tecne" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.tecnecover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <p className="maisontags">Research, UX Strategy, Interaction Design, Digital UX/UI, Product Design, Brand identity</p>
            {/* <span className="tag">UX STRATEGY</span>
            <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">DIGITAL UX</span>
            <span className="tag">DIGITAL UI</span>
            <span className="tag">PRODUCT DESIGN</span>
            <span className="tag">BRAND IDENTITY</span> */}
            <h1>tecne.</h1>

            <h3>
            A physical-digital IoT product to unlock a new chapter in training for combat sports. 
            </h3>
            <br />
            <h3>
            An underglove, to captures real-time data of athletes during their training, 
            accompanied by a mobile app that provides athletes with actionable insights 
            so that they can make actual gains from their training routines and improve 
            quickly as they prepare for their upcoming fights. 
            </h3>

          </header>


          <section className="header-post">
            <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  *This project was carried out while working at the design
                  agency, THINGS, in Milan.
                </p>
                <p>
                  <a href="/say-hello"><u>Get in touch</u></a> with me for more 
                  details related to this project; such as my
                  role, the problems i faced, the processes i 
                  used and how i addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  {/* <Img fluid={props.data.things_logo.childImageSharp.fluid} /> */}
                  <img className="span-4" src={things_logo} alt="things logo" />
                </div>
                <br />
                <p>
                  Things.is a design and innovation agency for the Internet of
                  Things. We create products and services with their own
                  experiential ecosystems outlined by multi-channel touch
                  points. We envision products and services in the IoT era
                  through a human-centred approach. www.things.is
                </p>
              </div>            

            </div>
          </section>

          <div className="process">           
          <div className="process-left">
                <h4>
                Context of the project
                </h4>
                <p>
                We had to design a physical-digital product for 
                athletes training for combat sports of MMA and Kickboxing. 
                </p>
                <p>
                Our aim was to develop a product for semi-professionals 
                who regularly train in a gym in preparation for a match. 
                </p>
          </div>
          <div className="process-right">
                <h4>
                Project overview
                </h4>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Designing a physical-digital product 
                  that assists athletes who are training for combat sports.
                </span>
                <br />
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of
                  THINGS, Milan
                </span>
                <br />
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Benchmarking competitors, planning & preparing for contextual interviews, 
                  mapping current & future user journey, user flows of the mobile app, 
                  wireframing of mobile app & website, Visual UI design, prototyping 
                  mobile app on Adobe XD.
                </span>
                <div className="challenge-client">
                <br />
                <h5>CLIENT</h5>
                  <div className="client-image">
                    <img className="span-3" src={tecne_logo} alt="bsmart logo" />
                  </div>
                </div>
            </div>
            </div>


          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>the challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                Using IoT technology as an enabler, we aimed to design a 
                product that would give these fighters access to meaningful 
                & actionable insights on how they are training, which otherwise
                 would be inaccessible to them and their trainers.
                </p>
                <p>
                To do this successfully, it was crucial that we designed an 
                experience that would blend in with their training routine with ease.
                </p>
              </div>

              {/* <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2018 - 2019
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 6 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Designing a physical-digital
                  product that assists athletes who are training for combat
                  sports.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> TEAM MEMBERS Design team of
                  THINGS, Milan
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Benchmarking competitors, planning &
                  preparing for contextual interviews, mapping current & future
                  user journey, user flows of the mobile app, wireframing of
                  mobile app & website, UI design consultation, prototyping
                  mobile app on Adobe XD.
                </span>
                <div className="challenge-client">
                  <h5>CLIENT</h5>
                  <div className="client-image">
                    <img className="span-3" src={tecne_logo} alt="tecne logo" />
                  </div>
                </div>
              </div> */}

            </div>

            <div className="image">
              <Img fluid={props.data.tecnechallenge.childImageSharp.fluid} />
            </div>

            <div className="project-outcome">
                <span className="project-outcome-link" onClick={scrolLWithUseRef}>
              see project outcomes >>
                </span>
              </div>

          </section>

          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>the approach</h2>

            <br />
            <br />
            <br />
            <br />

            <div className="process-image">
              <Img fluid={props.data.tecne_process.childImageSharp.fluid} />
            </div>

            <div className="changebg">
            <div className="process">           
            <div className="process-left">
            <h4>Understanding the sport </h4>
              </div>
              <div className="process-right">
              <p>
              Our clients were 2 retired professional MMA 
              & Kickboxing fighters from Italy, who gave 
              us a crash course on the sport.
              </p> 
              <p className="half-textlarge">
              We were invited to watch a Bellator Main Event 
              that involved multiple professional fights of 
              MMA and Kickboxing. 
              </p>               
              </div>
            </div>          
            
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess2.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess3.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess4.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess5.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess6.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess7.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess8.childImageSharp.fluid} />
            </Slider>


            <div className="process">                     
              <div className="process-right">
              <p>
              We took note of the different elements of the sport; things 
              such as peculiar entrances of fighters, types of announcement 
              made by judges, preparation before combat & from various ring
               sounds to feeling the main event atmosphere; and of course 
               we enjoyed the matches :)
              </p>           
              </div>
            </div>
            </div> 

            <div className="process">           
            <div className="process-left">
            <h4>Competitor research</h4>
              </div>
              <div className="process-right">
              <p>
              We came back to our design lab and started understanding the market by 
              looking at our direct & indirect competitors; which were Piq by Everlast, 
              Hykso, Corner and StrikeTec. 
              </p>     
              </div>
            </div>

            <p className="full-textlarge">
            We bought some of our competitor products and tried them out first hand; everything 
            from the unboxing of the product, the setting up of the sensors/wearable gloves to 
            tracking of data on the mobile app. 
            </p>

            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>

      
            {/* <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.tecneprocess10.childImageSharp.fluid} />
              </div>
            </div> */}


            <div className="process">           
            
              <div className="process-right">
              <p>
              We also looked took inspiration from other sports that used similar IoT driven technology to
              provide performance data to the athletes. This gave us great insights into what worked well 
              & what didn't.  
              </p>     
              </div>
            </div>

            <p className="full-textlarge">
            We learned that the initial product set-up and the subsequent times of 
            connecting the product with the mobile app were critical areas of the experience. 
            </p>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess11.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess10.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess12.childImageSharp.fluid} />
            </Slider>


            <div className="changebg">
            <div className="process">           
            <div className="process-left">
            <h4>Field research and interviews</h4>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              Consolidation of the desk research was made by 
              visiting a gym to observe and interview the fighters & trainers.  
              </p> 
              <p>
              We saw their training session; their preparation, 
              warm up, equipment they wear and the various technical
              training exercises they do. 
              </p>
              <p>
              Their behaviour in-between activity breaks and the dynamics the 
              trainers had with the fighters, such as the use of stopwatch with 
              audio to keep time and the type of instructions & motivation they gave. 
              </p>
              <p>
              Finally, the context of the gym such as the type of space 
              and surrounding noise was noted.  
              </p>           
              </div>
            </div> 

            <div className="image">
              <Img fluid={props.data.tecneprocess13.childImageSharp.fluid} />
            </div>      


            <div className="process">
              <div className="process-left">
              <p>
              After this, we interviewed both the fighters and trainers.
              </p>
              </div>
            </div>


            <div className="process">
              <div className="process-left">
                <p>
                  From the trainers we wanted to know:
                </p>
                  <ol>
                    <li>Various elements of the training sessions</li>
                    <li>Data that is hard to track with their eye</li>
                    <li>How they track fighter's current level</li>
                    <li>How they track improvement & goals</li>
                    <li>Type of feedback they give the fighters <br />and others...
                    </li>
                  </ol>
              </div>
              <div className="process-right">
                <p>
                From the fighters we wanted to know:
                </p>
                <ol>
                  <li>Dynamics between them & their trainer</li>
                  <li>Types of gloves & under-glove they wear</li>
                  <li>Usage of any mobile app for fitness</li>
                  <li>Types of training data useful to track</li>
                  <li>What motivations & training goals they set <br />and others...
                  </li>
                </ol>
              </div>
            </div>


            <div className="image">
              <Img fluid={props.data.tecneprocess18.childImageSharp.fluid} />
            </div>


            <p className="full-textlarge">
            Key learnings: <br />
            Beginners have to focus on developing their technique & know if they are doing the right moves. 
            <br />
            <br />

            
            For semi-pro/pro, endurance is more important & how long they can keep their best performance.  
            <br />
            <br />

            
            Visualising data insights quickly and easily - differentiate data shown in real-time 
            (during the session) with that shown after the session. 
            </p>
            </div>


            <div className="process">           
            <div className="process-left">
            <h4>Research analysis & Strategy</h4>
              </div>
              <div className="process-right">
              <p>
              Information from the research activities was organised and analysed to 
              identify insights. We did this by mapping the current journey for 
              the fighters and trainers.  
              </p>
              </div>
            </div>


            <p className="full-textlarge">
            This allowed us to develop insights about behaviours, problem areas & possible 
            opportunity gaps where our product could provide value to fighters & trainers 
            and meet their needs. 
            </p> 

            <div className="image">
              <Img fluid={props.data.tecneprocess19.childImageSharp.fluid} />
            </div>


            <div className="process">           
      
              <div className="process-right">
              <p className="half-textlarge">
              We outlined a UX Strategy to align our priorities and guide decision making.   
              </p>
              <p>
              This entailed creating a shared vision of the product, outlining 
              anticipated gains from UX design, identifying metrics to measure 
              success and finally ensuring that the experience offered by the 
              product achieved the stated business goals. Few elements of our 
              UX Strategy are outlined in the picture below.  
              </p>
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess20.childImageSharp.fluid} />
            </div>

            <div className="changebg">
            <div className="process">           
            <div className="process-left">
            <h4>User experience design</h4>
              </div>
              <div className="process-right">
              <p>
              We designed the whole experience and ecosystem surrounding the Tecne product.   
              </p>
              </div>
            </div>

            <p className="full-textlarge">
            We mapped the user flow for various use cases; from initial awareness, 
            unboxing of packaging, set-up of gloves, onboarding, product usage 
            and even user support.
            </p>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess21.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess22.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess23.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess24.childImageSharp.fluid} />
            </Slider>

            <p className="full-textlarge">
            By considering the entirety of the customer journey and all the touchpoints, 
            both offline & online, along the way we created a more robust & connected experiences. 
            </p>

            <div className="process">           
            
              <div className="process-right">
              <p>
              Next, various Task Map were made from the steps of the userflow.   
              </p>
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess25.childImageSharp.fluid} />
            </div>


            <div className="bluebg">
            <p className="full-textlargebluebg">
            This design tool was used to identify the features, 
            elements and different app screens that we had to design 
            based on the user needs. 
            </p>
            <p className="full-textlargebluebg">
            It was used as a framework to sketch low-fidelity 
            wireframes on paper and then consolidated digitally. 
            </p>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess26.childImageSharp.fluid} />
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess27.childImageSharp.fluid} />
            </div>
            </div>


            <div className="process">           
            <div className="process-left">
            <h4>Defining visual identity</h4>
              </div>
              <div className="process-right">
              <p>
              The brand identity of the Tecne product was made 
              with the values; Strong, Sporty, Solid & Dynamic.    
              </p>
              <p>
              This language was to be reflected all the various 
              touchpoints such as the mobile app, website, gloves 
              and communication materials.    
              </p>
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess28.childImageSharp.fluid} />
            </div>


            <div className="changebg">
            <div className="process">           
            <div className="process-left">
            <h4>Product design of the gloves</h4>
              </div>
              <div className="process-right">
              <p>
              As a starting point we were given an underglove equipped 
              with 13 sensors that captured various data. We had to 
              select appropriate material that gave a comfortable 
              feel & design its external look.     
              </p>
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess29.childImageSharp.fluid} />
            </div>

            <p className="full-textlarge">
            To give athletes an experience that felt natural & efficient 
            meant designing the Gloves that interacted with the mobile 
            app through gestures and haptic feedback.  
            </p>
            </div>


            <div className="process">           
            <div className="process-left">
            <h4>Testing gloves & mobile app</h4>
              </div>
              <div className="process-right">
              <p>
              The first prototype of the gloves and developed 
              mobile app was integrity tested to understand 
              what worked and what didn't.      
              </p>
              </div>
            </div>

            <p className="full-textlarge">
            First we made sure that the gloves and app were properly 
            communicating with each other, with regards to charging, 
            pairing, data transfer and data visualisation on the app 
            while using the gloves.   
            </p>

            <div className="process">           
            <div className="process-left">
            <div className="image">
              <Img fluid={props.data.tecneprocess30.childImageSharp.fluid} />
            </div>
            </div>              
              <div className="process-right">
              <p>
              Next any technical issues and bugs 
              within the app were identified. 
              The gloves were also tested for 
              its material strength, quality 
              and comfort.       
              </p>
              <p>
              Finally we considered the entire experience 
              from the design point of view to identify 
              elements that can be further consolidated.        
              </p>
              <p>
              Before the launch of the product we planned 
              to give the gloves & app to few professional 
              fighters to get more feedback from them in 
              order to further refine the product.       
              </p>
              </div>
            </div>

          </section>



          <section id="outcome-section" ref={outcomeDiv} className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>the outcome</h2>
              <h3>
                We have designed a perfect combo to help fighters 
                and trainers really understand how they are training 
                so that they can make actual gains in combat sports training. 
              </h3>
              <div className="outcome">
                <div className="outcome-left">
                <p>
                The Tecne Sensor Wraps MK II is a state of the art 
                underglove laced with sensors with exceptionally comfy 
                and snug fit. They collect real-time data on the fighter’s
                 training routine. Built-in gesture capability make the 
                 gloves functional & fit naturally to training routines.    
                </p>
                </div>
                <div className="outcome-right">
                <p>
                These are accompanied with the Tecne mobile app; 
                where the data collected from the gloves are visualised.
                We track movements over a variety of data parameters 
                and characteristics to give a complete assessment of
                the fighter's training sessions. 
                </p>
                </div>
              </div>
              <h3>
              Overall, we have enriched the training sessions of combat
              sports by giving fighters and trainers access to data & 
              insights, thus equipping them to take immediate actions 
              based on real-accurate data which they wouldn't have known
              by simply observing.
              </h3>
              <p>
                Visit <a href="https://tecne.pro">tecne.pro</a> to find out
                more.
              </p>
            </div>
          </section>
        </div>
      </div>


      <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.tecneoutcome46.childImageSharp.fluid} />
        </div>
      </section>




      {/* remove these breaks below when the prototype code lines are un-commented */}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />




                  {/* commented the below prototype link section till the prototype is made */}


      {/* <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
          <div className="changebg">
          <br />
          <br />
          <div className="process">           
            <div className="process-left">
              <h4>
              Prototype of the mobile app
              </h4>
              </div>
              <div className="process-right">
              <div>
              <img className="protologo" src={adobelogo} alt="" />
              </div>
              
              <div className="resume-download">
              <a href={"https://medium.com/@sagarsatam"} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
              See Prototype
              </a>
              </div> 
                             
              </div>
            </div>  
          </div>
      </section>
      </div>
      </div> */}



      <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.tecneoutcome45.childImageSharp.fluid} />
        </div>
      </section>

      {/* <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.tecneoutcome50.childImageSharp.fluid} />
        </div>
      </section> */}



      <div className="project-wrapper">
          <div className="project-content">
          {/* <section className="process"> */}
          <div className="changebg">

          
          <div className="process">           
            <div className="process-left">
              <h4>
              Problems faced during the project 
              and how they were solved
              </h4>
              </div>
              <div className="process-right">
              <p>
              The challenge was to identify ways in which our product 
              assists the coaches to do their job better and not to 
              replace their role. 
              </p> 
              <p>
              After collaborating with the coaches we found out our
               USP which was to equip them with factual data which 
               they could not know during their training sessions; 
               such as data about impact strength and area of impact 
               of each hit. 
              </p>               
              </div>
            </div>
            

            <div className="process">           
              <div className="process-right">
              <p>
              Another challenge was to ensure that our product did not
               interfere or distract the current training routines and sessions.
              </p> 
              <p>
              The insights from our research allowed us to design various
               micro & macro interactions that made sure our product 
               complimented the workflow of coaches and trainers. 
               For e.g. Highlight-cards gave quick data insights.
              </p>               
              </div>
            </div>

            <div className="process">           
              <div className="process-right">
              <p>
              Coaches during training need to see live feed of
               data of their trainers and also see data of the 
               elapsed time. The use of bluetooth (to connect 
               and exchange data/information between the gloves
                and mobile app) meant putting considerable strain 
                on the database, resulting in slow loading time.
              </p> 
              <p>
              Our solution was to take snapshots (pictures) of 
              the data during training and store that data locally.
               So  if the user was to scroll back in the timeline, 
               either during or at the end of the training session,
                instead of calling the data from the server we simply
                 showed the snapshots thus eliminating any loading time. 
              </p>               
              </div>
            </div>

            <br />
            <br />
            <div className="process">           
            <div className="process-left">
                  <h4>
                  Reflection - what I learnt during the project & what I could have done better.
                  </h4>
            </div>
              <div className="process-right">
              <p>
              Getting people to talk about an important, but highly complex topic was difficult.    
              </p> 
              <p>
              We approached people in different ways (short survey, contextual interview, 
              phone conversation, in-person chats) depending on their comfort. Our 
              strategy involved raising awareness around the topic, and being open 
              to listening to stories about people's personal experiences. 
              </p>                
              </div>
            </div>
          </div>
          <br />
          
          
          <p className="full-text">
          Thank you & keep training hard and smart! <span role="img" aria-labelledby="tecneemoji">💪📈</span> 
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          
          <div className="full-text">
          <div className="hp-featured__hr-line" />
          <h5>NEXT PROJECT</h5>
          </div>
          <br />


          {/* </section> */}
        </div>
      </div>


      <section id="two" className="tiles inner">
      {/* <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4> */}
            <div className="tiles__wrapper">
              <a href="/work/service-design-postop">
                <div className="tiles__content">
                  <div className="tiles__info">
                    {/* <div className="tiles__number">01</div> */}

                    {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                    <div className="tiles__text">
                    Improved post surgery outcomes by enabling patients to 
                    manage their recovery independently with confidence through 
                    remote monitoring by clinical teams.
                    </div>
                    <br />
                    <p className="maisontags-card">AI-enabled, Research, UX Strategy, Service Design, User Experience, Digital UX/UI</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>USER EXPERIENCE</li>
                        <li>DIGITAL UX/UI</li>
                        <li>INTERACTION DESIGN</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic04} alt="" />
                </div>
              </a>
            </div>
      </section>

      










      


  </div>
  </Layout>
)
}

export default ServiceDesignTecne

export const fluidTecneImage = graphql`
  fragment fluidTecneImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    tecnecover: file(
      relativePath: { eq: "projects/service/tecne/cover/tecne-cover-image.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecne_logo: file(
      relativePath: { eq: "projects/service/tecne/tecne-logo.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge: file(
      relativePath: { eq: "projects/service/tecne/challenge/1.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess2: file(
      relativePath: { eq: "projects/service/tecne/process/2.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess3: file(
      relativePath: { eq: "projects/service/tecne/process/3.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess4: file(
      relativePath: { eq: "projects/service/tecne/process/4.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess5: file(
      relativePath: { eq: "projects/service/tecne/process/5.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess6: file(
      relativePath: { eq: "projects/service/tecne/process/6.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess7: file(
      relativePath: { eq: "projects/service/tecne/process/7.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess8: file(
      relativePath: { eq: "projects/service/tecne/process/8.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess9: file(
      relativePath: { eq: "projects/service/tecne/process/9.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess10: file(
      relativePath: { eq: "projects/service/tecne/process/10.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess11: file(
      relativePath: { eq: "projects/service/tecne/process/11.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess12: file(
      relativePath: { eq: "projects/service/tecne/process/12.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess13: file(
      relativePath: { eq: "projects/service/tecne/process/13.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess14: file(
      relativePath: { eq: "projects/service/tecne/process/14.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess15: file(
      relativePath: { eq: "projects/service/tecne/process/15.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess16: file(
      relativePath: { eq: "projects/service/tecne/process/16.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess17: file(
      relativePath: { eq: "projects/service/tecne/process/17.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess18: file(
      relativePath: { eq: "projects/service/tecne/process/18.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess19: file(
      relativePath: { eq: "projects/service/tecne/process/19.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess20: file(
      relativePath: { eq: "projects/service/tecne/process/20.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess21: file(
      relativePath: { eq: "projects/service/tecne/process/21.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess22: file(
      relativePath: { eq: "projects/service/tecne/process/22.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess23: file(
      relativePath: { eq: "projects/service/tecne/process/23.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess24: file(
      relativePath: { eq: "projects/service/tecne/process/24.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess25: file(
      relativePath: { eq: "projects/service/tecne/process/25.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess26: file(
      relativePath: { eq: "projects/service/tecne/process/26.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess27: file(
      relativePath: { eq: "projects/service/tecne/process/27.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess28: file(
      relativePath: { eq: "projects/service/tecne/process/28.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess29: file(
      relativePath: { eq: "projects/service/tecne/process/29.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess30: file(
      relativePath: { eq: "projects/service/tecne/process/30.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess31: file(
      relativePath: { eq: "projects/service/tecne/process/31.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess32: file(
      relativePath: { eq: "projects/service/tecne/process/32.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess33: file(
      relativePath: { eq: "projects/service/tecne/process/33.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess34: file(
      relativePath: { eq: "projects/service/tecne/process/34.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess35: file(
      relativePath: { eq: "projects/service/tecne/process/35.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess36: file(
      relativePath: { eq: "projects/service/tecne/process/36.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess37: file(
      relativePath: { eq: "projects/service/tecne/process/37.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess38: file(
      relativePath: { eq: "projects/service/tecne/process/38.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess39: file(
      relativePath: { eq: "projects/service/tecne/process/39.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess40: file(
      relativePath: { eq: "projects/service/tecne/process/40.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess41: file(
      relativePath: { eq: "projects/service/tecne/process/41.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess42: file(
      relativePath: { eq: "projects/service/tecne/process/42.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess43: file(
      relativePath: { eq: "projects/service/tecne/process/43.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess44: file(
      relativePath: { eq: "projects/service/tecne/process/44.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome46: file(
      relativePath: { eq: "projects/service/tecne/outcome/46.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome45: file(
      relativePath: { eq: "projects/service/tecne/outcome/45.jpg" }
    ) {
      ...fluidTecneImage
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_process: file(
      relativePath: { eq: "projects/process/tecne-process.png" }
    ) {
      ...fluidTecneImage
    }
    project1: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidTecneImage
    }
    project2: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidTecneImage
    }
  }
`
